<template>
  <div style="overflow: hidden; position: relative;">
    <div class="r-header">
      <el-input v-model="employeeName" placeholder="姓名" clearable @keyup.enter.native="onSearch('search')"></el-input>
      <el-button type="primary" plain @click="onSearch('search')">查询</el-button>
      <ElXlsx @upData="upData" :isPlain="true" />
      <el-button type="primary" plain @click="reportTable">Excle导出</el-button>
      <el-button type="primary" @click="addMethod()">新增</el-button>
    </div>
    <el-table :data="tableData" style="width: 100%;" max-height="654" border v-loading="loading" ref="report-table">
      <el-table-column fixed prop="employeeName" label="姓名"></el-table-column>
      <el-table-column prop="center" label="中心"></el-table-column>
      <el-table-column prop="department" label="部门"></el-table-column>
      <el-table-column prop="job" label="岗位"></el-table-column>
      <el-table-column prop="itemRank" label="职能"></el-table-column>
      <el-table-column prop="idCard" label="身份证号" width="180"></el-table-column>
      <el-table-column prop="phone" label="个人联系方式"></el-table-column>
      <el-table-column prop="birthday" label="出生日期"></el-table-column>
      <el-table-column prop="birthdayY" label="阴历出生日期">
        <template slot-scope="scope" >
          {{scope.row.birthdayY?scope.row.birthdayY:'无'}}
        </template>
      </el-table-column>
      <el-table-column prop="enterDate" label="入司时间" width="120">
        <template slot-scope="scope">
          <div>
            {{scope.row.enterDate? scope.row.enterDate.substring(0,scope.row.enterDate.indexOf("T")):scope.row.enterDate}}
          </div>
        </template>
      </el-table-column>
      <el-table-column fixed="right" label="操作" width="150" v-if="!noReport">
        <template slot-scope="scope">
          <el-button @click.native.prevent="changeRow(scope.$index, tableData)" icon="el-icon-edit" type="text">
            修改
          </el-button>
          <el-button @click.native.prevent="deleteRow(scope.$index, tableData)" icon="el-icon-delete" type="text">
            删除
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination @current-change="handleCurrentChange" :current-page="page" :page-size="rows"
      layout="total, prev, pager, next, jumper" :total="total">
    </el-pagination>

    <el-dialog :title="(flag === 'add' ? '新增':'修改')+'详情页'" :visible.sync="dialogVisible" width="40%" top="18vh"
      @close="handleClose" :modal-append-to-body="false" :append-to-body="true">
      <div class="cont-box">
        <el-form :inline="true" :model="rosterImport" class="my-form" ref="rosterImport" :rules="rule01">
          <el-form-item prop="employeeName" label="姓名">
            <el-input v-model="rosterImport.employeeName" placeholder="请输入" clearable></el-input>
          </el-form-item>
          <el-form-item prop="center" label="中心">
            <el-input v-model="rosterImport.center" placeholder="请输入" clearable></el-input>
          </el-form-item>
          <el-form-item prop="department" label="部门">
            <el-input v-model="rosterImport.department" placeholder="请输入" clearable></el-input>
          </el-form-item>
          <el-form-item prop="job" label="岗位">
            <el-input v-model="rosterImport.job" placeholder="请输入" clearable></el-input>
          </el-form-item>
          <el-form-item prop="itemRank" label="职能">
            <el-input v-model="rosterImport.itemRank" placeholder="请输入" clearable></el-input>
          </el-form-item>
          <el-form-item prop="idCard" label="身份证号">
            <el-input v-model="rosterImport.idCard" placeholder="请输入" clearable minlength="18" maxlength="18"
              @input="bindInputIdCard"></el-input>
          </el-form-item>
          <el-form-item prop="phone" label="个人联系方式">
            <el-input v-model="rosterImport.phone" placeholder="请输入" clearable minlength="11" maxlength="11"></el-input>
          </el-form-item>
          <el-form-item prop="birthday" label="出生日期">
            <el-input v-model="rosterImport.birthday" :disabled="true"></el-input>
          </el-form-item>
          <el-form-item prop="birthdayY" label="阴历出生日期">
            <el-date-picker v-model="rosterImport.birthdayY" type="date" placeholder="选择日期" value-format="yyyy-MM-dd">
            </el-date-picker>
          </el-form-item>
          <el-form-item prop="enterDate" label="入司日期">
            <el-date-picker v-model="rosterImport.enterDate" placeholder="请选择" type="date" value-format="yyyy-MM-dd"
              clearable></el-date-picker>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="subCont('rosterImport')">确认</el-button>
      </span>
    </el-dialog>
    <el-dialog title="导入确认" :visible.sync="dialogConfirm" width="90%" top="8vh" @close="dialogConfirm = false"
      :modal-append-to-body="false" :append-to-body="true">
      <RosterConfirm :datas="dataArr"></RosterConfirm>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogConfirm = false">取 消</el-button>
        <el-button type="primary" @click="importSub" :disabled="btnDisabled">确认</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
  import XLSX from 'xlsx'
  import FileSaver from 'file-saver'

  import ElXlsx from '../../components/ElXlsx.vue'
  import RosterConfirm from '../../components/RosterConfirm.vue'
  export default {
    name: 'Roster',
    components: {
      ElXlsx,
      RosterConfirm
    },
    data() {
      return {
        noReport: false,
        btnDisabled: false,
        dataArr: [],
        loading: true,
        dialogVisible: false,
        dialogConfirm: false,
        drawer: false,
        employeeName: '',
        rosterImport: {
          employeeName: '',
          center: '',
          department: '',
          job: '',
          itemRank: '',
          idCard: '',
          phone: '',
          birthday: '',
          enterDate: ''
        },
        flag: 'add',
        tableData: [],
        curData: {},
        rule01: {
          employeeName: [{
            required: true,
            message: '请输入',
            trigger: 'blur'
          }],
          center: [{
            required: true,
            message: '请输入',
            trigger: 'blur'
          }],
          department: [{
            required: true,
            message: '请输入',
            trigger: 'blur'
          }],
          job: [{
            required: true,
            message: '请输入',
            trigger: 'blur'
          }],
          itemRank: [{
            required: true,
            message: '请输入',
            trigger: 'blur'
          }],
          idCard: [{
            required: true,
            pattern: /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/,
            message: '请输入正确的身份证号',
            trigger: 'blur'
          }],
          phone: [{
            required: true,
            pattern: /^1[3|4|5|6|7|8|9][0-9]\d{8}$/,
            message: "请输入正确的手机号码",
            trigger: "blur"
          }],
          birthday: [{
            required: false,
            message: '请输入',
            trigger: 'blur'
          }],
          enterDate: [{
            required: true,
            message: '请输入',
            trigger: 'blur'
          }]
        },
        page: 1,
        rows: 10,
        total: null,
        items: ['employeeName', 'center', 'department', 'job', 'itemRank', 'idCard', 'phone', 'birthday', 'enterDate',
          'corp'
        ]
      }
    },
    mounted() {
      this.getList()
    },
    methods: {
      reportTable() {
        this.noReport = true
        this.asyncGetList()
      },
      async asyncGetList() {
        this.rows = -1
        await this.getList()
        this.exportExcel('花名册')
        this.noReport = false
        this.rows = 10
        this.page = 1
        this.tableData.splice(10)
      },
      exportExcel(excelName) {
        try {
          const $e = this.$refs['report-table'].$el;
          let $table = $e.querySelector('.el-table__fixed');
          if (!$table) {
            $table = $e;
          }
          // 为了返回单元格原始字符串，设置{ raw: true }
          const wb = XLSX.utils.table_to_book($table, {
            raw: true
          });
          const wbout = XLSX.write(wb, {
            bookType: 'xlsx',
            bookSST: true,
            type: 'array'
          });
          FileSaver.saveAs(
            new Blob([wbout], {
              type: 'application/octet-stream'
            }), `${excelName}.xlsx`)
        } catch (e) {
          if (typeof console !== 'undefined') console.error(e);
        }
      },
      importSub() {
        this.btnDisabled = true
        this.$axios.post('/hr-employees/saveBatch', this.dataArr).then(res => {
          this.$message({
            type: res.data.code === 400 ? 'error' : 'success',
            message: res.data.code === 400 ? res.data.msg : '添加成功。'
          })
          this.dialogConfirm = false
          this.btnDisabled = false
          this.getList()
        })
      },
      upData(e) {
        try {
          let dataArr = this.$replaceString(e, this.items)
          this.dataArr = dataArr
          this.dialogConfirm = true
        } catch {
          console.log('出错了')
        }
      },
      bindInputIdCard(e) {
        this.rosterImport.birthday = e.substring(6, 10) + "-" + e.substring(10, 12) + "-" + e.substring(12, 14)
      },
      async getList(e) {
        this.loading = true
        if (e === 'search') {
          this.page = 1
        }
        await this.$axios.post('/hr-employees/findListPage', {
          current: this.page,
          size: this.rows,
          employeeName: this.employeeName,
          corp: '0'
        }).then(res => {
          this.loading = false
          this.tableData = res.data.data.records
          this.total = res.data.data.total || res.data.data.records.length
        })
      },
      deleteRow(index, rows) {
        var that = this
        this.$confirm('此操作将永久删除该文件, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.$axios.post('/hr-employees/remove', {
            id: rows[index].id,
            idCard: rows[index].idCard
          }).then(res => {
            that.$message({
              type: res.data.code === 400 ? 'error' : 'success',
              message: res.data.code === 400 ? res.data.msg : '删除成功。'
            })
            this.getList()
          })
        })
      },
      handleCurrentChange(val) {
        this.page = val
        this.getList()
      },
      addMethod() {
        this.rosterImport = {}
        this.dialogVisible = true
        this.flag = 'add'
      },
      changeRow(index, data) {
        this.dialogVisible = true
        this.rosterImport = JSON.parse(JSON.stringify(data[index]))
        this.flag = 'change'
      },
      onSearch(e) {
        this.getList(e)
      },
      subCont(formName) {
        var that = this
        this.$refs[formName].validate((valid) => {
          if (valid) {
            if (this.flag === 'add') {
              this.$axios.post('/hr-employees/saveOrUpdate ', this.rosterImport).then(res => {
                that.dialogVisible = false
                that.$message({
                  type: res.data.code === 400 ? 'error' : 'success',
                  message: res.data.code === 400 ? res.data.msg : '添加成功。'
                })
                that.getList()
              })
            } else {
              this.$axios.post('/hr-employees/saveOrUpdate', this.rosterImport).then(res => {
                that.$message({
                  type: res.data.code === 400 ? 'error' : 'success',
                  message: res.data.code === 400 ? res.data.msg : '更新成功。'
                })
                that.getList()
              })
            }
            this.dialogVisible = false
          } else {
            return false;
          }
        })
      },
      resetForm(formName) {
        this.$refs[formName].resetFields();
      },
      handleClose() {
        this.resetForm('rosterImport')
      }
    }
  }
</script>

<style scoped>
  .r-header {
    display: flex;
    margin-bottom: 20px;
  }

  .r-header .el-input {
    width: 260px;
    margin-right: 6px;
  }

  .r-header>>>.el-button {
    margin-left: 0;
  }

  .el-table {
    box-sizing: border-box;
  }

  .drawer-cont {
    box-sizing: border-box;
    padding: 0 20px;
  }

  .el-tag {
    /* cursor: pointer; */
    padding: 0 24px;
  }

  .el-table>>>.cell .el-button {
    padding: 0;
  }

  /*  .el-dialog__wrapper {
    height: 100%;
  }
  .el-dialog__wrapper >>> .el-dialog {
    height: 80%;
    overflow-y: auto;
  } */
  .cont-box {
    padding: 0 10px;
    /* overflow-y: auto; */
  }

  .list-cont {
    display: flex;
    flex-wrap: wrap;
    margin: 0 1em;
    padding: 14px 0;
  }

  .list-cont01 {
    display: flex;
    flex-wrap: wrap;
    margin: 0 1em;
    padding: 14px 0;
  }

  .list-cont li {
    width: 25%;
    display: flex;
    padding: 6px 0;
    align-items: center;
  }

  .list-cont01>div {
    width: 25%;
    display: flex;
    padding: 6px 0;
    align-items: center;
    margin-bottom: 0;
  }

  .title {
    line-height: 34px;
    background: #f9f9f9;
    text-indent: 1em;
    color: #999999;
  }

  .list-cont li>label {
    white-space: nowrap;
  }

  .list-cont01>div>>>.el-form-item__label {
    white-space: nowrap;
  }

  .list-cont01>div>>>.el-form-item__content {
    flex: 1;
    margin: 0 1em 0 .5em;
  }

  .list-cont li>p {
    flex: 1;
    color: #323232;
    margin: 0 10px;
    min-height: 20px;
    border-bottom: 1px solid #dedede;
  }

  .list-cont li>.el-input,
  .list-cont li>.el-select,
  .list-cont li>.el-textarea {
    flex: 1;
    margin: 0 1em 0 .5em;
  }

  .el-date-editor.el-input,
  .el-date-editor.el-input__inner {
    width: 100%;
  }

  .el-select {
    width: 100%;
  }

  .my-form {
    display: flex;
    flex-wrap: wrap;
    padding-top: 20px;
  }

  .my-form>.el-form-item {
    box-sizing: border-box;
    width: 50%;
    margin: 0;
    padding: 10px;
    display: flex;
  }

  .my-form>>>.el-form-item__label {
    white-space: nowrap;
  }

  .my-form>>>.el-form-item__content {
    flex: 1;
  }

  .upload {
    display: inline-block;
    margin-right: 6px;
  }

  .el-button {
    margin-right: 6px;
  }
</style>
